import './App.css';

function App() {
  let images = ['1.jpg','2.JPG', '3.jpeg','5.jpeg']
  var image = images[Math.floor(Math.random()*images.length)];
  return (
    <div className="App"  style={{
      backgroundImage: `url("/images/${image}")`
    }}>
      <div className={'overlay'}></div>
      <div className="content">
        <img  alt='mymicael' className='img-fluid image rounded-circle'  src='/images/image.JPG' />
        <h1 className={'my-3'}>
          MyMicael
        </h1>
        <p>Web Freelancer | Devops</p>
        <p>Love sports and share my passion</p>
        <p>
          <span className="badge badge-pill py-1 px-3 badge-light">Hike 🥾</span>
          <span className="badge badge-pill py-1 px-3 badge-light mx-2">Bike 🚵</span>
          <span className="badge badge-pill py-1 px-3 badge-light">Basketball 🏀</span>
          <span className="badge badge-pill py-1 px-3 badge-light mx-2">Ski Touring ⛷</span>
          <span className="badge badge-pill py-1 px-3 badge-light mr-2">Techno music 🎶</span>
          <span className="badge badge-pill py-1 px-3 badge-light ">Paragliding 🪂</span>
        </p>
        <p><a href="mailto:mymicael.contact@gmail.com" className={['btn btn-light mt-3']}> Contact me !</a></p>
      </div>
    </div>
  );
}

export default App;
